import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

const language = navigator.language === 'ru-RU' ? 'ru' : 'en'

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    title: "Title",
                    description: "Description",
                    first_name: "First name",
                    last_name: "Last name",
                    handle: "Handle",
                    birthday: "Birthday",
                    gender: "Gender",
                    password: "Very secret password",
                    about: "About",
                    sign_in: "Sign in",
                    sign_up: "Sign up",
                    subscribe_error: "Subscription attempt failed",
                    search: "Search",
                    profile: "Profile",
                    notifications: "Notifications",
                    logout: "Logout",
                    departure: "Departure",
                    travel_description_help: "Tell us about the route, cities, places you are going to visit. Specify the exact time and place of departure",
                    create: "Create",
                    travel_start_date: "Travel start date",
                    join: "Join",
                    already_request: "You have already made a request",
                    already_joined: "You have already joined",
                    you_refused: "You were refused",
                    finished: "Finished",
                    must_be_registered: "To join you must be registered",
                    nobody_disturbed: "Nobody disturbed you (≖.≖)",
                    reject: "Reject",
                    accept: "Accept",
                    refresh: "Refresh",
                    be_first: "Be the first!",
                    nothing_yet: "There's nothing here yet :(",
                    man: "Man",
                    woman: "Woman",
                    old_password: "Old password",
                    new_password: "New Password",
                    years_old: "years old",
                    create_journey: "Create a journey",
                    about_me: "About me",
                    my_travels: "My travels",
                    haven_not_traveled: "I haven't traveled yet :(",
                    service_unavailable: "Service Unavailable",
                    server_error: "Server Error",
                    page_not_found: "Page Not Found",
                    forbidden: "Forbidden",
                    unexpected_error: "Unexpected Error",
                    service_unavailable_description: "Sorry, we are doing some maintenance. Please check back soon.",
                    server_error_description: "Whoops, something went wrong on our servers.",
                    page_not_found_description: "Sorry, the page you are looking for could not be found.",
                    forbidden_description: "Sorry, you are forbidden from accessing this page.",
                    language: "Language",
                    something_wrong: "Something went wrong"
                },
            },
            ru: {
                translation: {
                    title: "Название",
                    description: "Описание",
                    first_name: "Имя",
                    last_name: "Фамилия",
                    handle: "Хэндл",
                    birthday: "День рождения",
                    gender: "Пол",
                    password: "Очень секретный пароль",
                    about: "О вас",
                    sign_in: "Войти",
                    sign_up: "Зарегистрироваться",
                    subscribe_error: "Попытка подписки завершилась ошибкой",
                    search: "Поиск",
                    profile: "Профиль",
                    notifications: "Уведомления",
                    logout: "Выйти",
                    departure: "Отправление",
                    travel_description_help: "Расскажите о маршруте, городах, местах, которые собираетесь посетить. Укажите точное время и место отправки",
                    create: "Создать",
                    travel_start_date: "Дата начала путешествия",
                    join: "Присоединиться",
                    already_request: "Вы уже сделали запрос",
                    already_joined: "Вы уже присоединились",
                    you_refused: "Вам отказали",
                    finished: "Окончено",
                    must_be_registered: "Чтобы присоединиться, нужно зарегистрироваться",
                    nobody_disturbed: "Никто не беспокоил тебя (≖.≖)",
                    reject: "Отклонить",
                    accept: "Принять",
                    refresh: "Обновить",
                    be_first: "Будь первым!",
                    nothing_yet: "Здесь пока ничего нет :(",
                    man: "Мужчина",
                    woman: "Женщина",
                    old_password: "Старый пароль",
                    new_password: "Новый пароль",
                    years_old: "лет",
                    create_journey: "Создать путешествие",
                    about_me: "Обо мне",
                    my_travels: "Мои путешествия",
                    haven_not_traveled: "Я еще не путешествовал :(",
                    service_unavailable: "Сервис недоступен",
                    server_error: "Ошибка сервера",
                    page_not_found: "Страница не найдена",
                    forbidden: "Доступ запрещен",
                    unexpected_error: "Неожиданная ошибка",
                    service_unavailable_description: "Извините, мы проводим технические работы. Пожалуйста, зайдите позже.",
                    server_error_description: "Упс, что-то пошло не так на наших серверах.",
                    page_not_found_description: "Извините, страница, которую вы ищете, не найдена.",
                    forbidden_description: "Извините, у вас нет доступа к этой странице.",
                    language: "Язык",
                    something_wrong: "Что-то пошло не так"
                },
            },
        },
        lng: language,
        fallbackLng: language,
        interpolation: {
            escapeValue: false, // Не требуется для React
        },
    });

export default i18n;
